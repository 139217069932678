import { useMemo, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Input, Select } from '@formily/antd-v5';
import { Button, Card, Modal, message } from 'antd';
import { AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { deleteRole, getRoleList } from 'src/api/clients/system';
import { IGetRoleListReq, IRoleItem } from 'src/api/types/system';

import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { schema, columns } from './schema';
import { RoleModal } from './role-modal';
import { AssignMenuDrawer } from './assign-menu';
import { BindingUserDrawer } from './binding-user';
import { BindingDataScope } from './binding-data';

const QueryTableRolesManage = 'SystemRolesManageTableName';

export default function SystemMenusManage() {
  const [open, { setTrue, setFalse }] = useBoolean(false);
  const { t } = useTranslation();
  const [visible, { setTrue: setAssignMenuTrue, setFalse: setAssignMenuTree }] = useBoolean(false);
  const [userVisible, { setTrue: setUserListTrue, setFalse: setUserListFalse }] = useBoolean(false);
  const [dataVisible, { setTrue: setDataScopeTrue, setFalse: setDataScopeFalse }] =
    useBoolean(false);
  const [currentRole, setCurrentRole] = useState<IRoleItem>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const getRolesTableList = useMemoizedFn(async (params: IGetRoleListReq) => {
    const result = await getRoleList(params);
    return {
      data: result.rows,
      total: result.count,
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onDeleteRoleByIds = useMemoizedFn(async (ids: number[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定用户吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteRole(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      QueryTableRolesManage,
    );
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 220,
        renderType: 'Operation',
        renderProps: (col: IRoleItem) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setCurrentRole(col);
                  setTrue();
                },
              },
              {
                label: '菜单权限',
                onClick: () => {
                  setCurrentRole(col);
                  setAssignMenuTrue();
                },
              },
              {
                label: '数据权限',
                onClick: () => {
                  setCurrentRole(col);
                  setDataScopeTrue();
                },
              },
              {
                label: '已绑用户',
                onClick: () => {
                  setCurrentRole(col);
                  setUserListTrue();
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteRoleByIds([col.roleId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">{t('menu.role')}</div>
      <QueryTable.Provider name={QueryTableRolesManage} requestFn={getRolesTableList}>
        <QueryTable.Filter schema={schema} components={{ Input, Select }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="roleId"
          scroll={{ x: 1200 }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          tabsNode={
            <Button icon={<AiOutlinePlus />} type="primary" onClick={setTrue}>
              新增角色
            </Button>
          }
          extraRightTool={
            <Button
              disabled={!selectedRowKeys.length}
              color="danger"
              icon={<AiOutlineDelete />}
              onClick={() => {
                onDeleteRoleByIds(selectedRowKeys as number[]);
              }}
              variant="outlined">
              批量删除
            </Button>
          }
        />
      </QueryTable.Provider>
      <RoleModal
        open={open}
        onOk={onRefresh}
        data={currentRole}
        onCancel={() => {
          setFalse();
          setCurrentRole(undefined);
        }}
      />
      <AssignMenuDrawer open={visible} role={currentRole} onClose={setAssignMenuTree} />
      <BindingUserDrawer
        roleId={currentRole?.roleId}
        roleName={currentRole?.roleName}
        open={userVisible}
        onClose={() => {
          setUserListFalse();
          setCurrentRole(undefined);
        }}
      />
      <BindingDataScope
        open={dataVisible}
        onOk={onRefresh}
        roleId={currentRole?.roleId}
        onCancel={() => {
          setDataScopeFalse();
          setCurrentRole(undefined);
        }}
      />
    </Card>
  );
}
